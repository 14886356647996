<template>
	<div class="tabulation">
		<el-card class="chart-card">
			<div style="margin-top: 20px;">
				<el-form ref="ruleFormRef" :model="form" :rules="rules" class="demo-ruleForm" :size="formSize"
					status-icon>
					<!-- 短信模板编号 -->
					<el-form-item label="短信模板编号" prop="templateCode">
						<div class="aaaa">
							<el-input v-model="form.templateCode" placeholder="请填写短信模板编号" size="large"></el-input>
						</div>
					</el-form-item>
					<!-- 短信内容 -->
					<el-form-item label="短信内容" prop="messageContent">
						<div class="aaaa">
							<el-input v-model="form.messageContent" size="large" type="textarea"
								placeholder="请填写短信内容"></el-input>
						</div>
					</el-form-item>
					<!-- 是否存在变量 -->
					<el-form-item label="是否存在变量" prop="isVariable">
						<el-select v-model="form.isVariable" placeholder="请选择是否存在变量" size="large">
							<el-option label="是" value="1" style="width:100%; text-align-last: center;"></el-option>
							<el-option label="否" value="2" style="width:100%; text-align-last: center;"></el-option>
						</el-select>
					</el-form-item>
					<!-- 短信变量模板 -->
					<el-form-item label="短信变量模板" prop="messageVariable">
						<div class="aaaa">
							<el-input v-model="form.messageVariable" size="large" type="textarea"
								placeholder="请填写短信变量模板"></el-input>
						</div>
					</el-form-item>
					<!-- 是否发布 -->
					<el-form-item label="是否发布" prop="isRelease">
						<el-select v-model="form.isRelease" placeholder="请选择是否发布" size="large">
							<el-option label="是" value="1" style="width:100%; text-align-last: center;"></el-option>
							<el-option label="否" value="2"
								style="width:100%; text-align-last: center;"></el-option>
						</el-select>
					</el-form-item>
					<!-- 备注 -->
					<el-form-item label="备注" prop="reason">
						<div class="aaaa">
							<el-input v-model="form.reason" size="large" type="textarea" placeholder="请填写备注"></el-input>
						</div>
					</el-form-item>
				</el-form>
			</div>
			<el-button type="primary" size="large" style="margin-top: 20px;margin-left: 110px;" :loading="ConfirmLoading"
				@click="onSubmit">提交</el-button>
		</el-card>
	</div>
</template>
<script>
	import '@wangeditor/editor/dist/css/style.css' // 引入 css
	import {
		Editor,
		Toolbar,
		Plus,
	} from '@wangeditor/editor-for-vue'
	import {
		ref,
		reactive,
		onMounted,
		shallowRef,
		onBeforeUnmount,
	} from "vue";
	// import { Zzlist,Addlist} from '../../utils/api'
	import {
		templateAdd,
	} from '../../../utils/api'
	import {
		ElMessage
	} from "element-plus";
	import qs from 'qs'
	import {
		useRouter,
		useRoute
	} from "vue-router";

	import * as imageConversion from 'image-conversion'
	import {
		useStore
	} from "vuex";
	export default {
		name: "templateAdd",
		components: {
			Editor,
			Toolbar,
			Plus,
		},
		setup() {
			const route = useRoute();
			// 清空图片
			const qingkong = ref('')
			let data = ref(null);
			// 按钮加载
			const ConfirmLoading = ref(false)

			// 上传图片
			const header = ref({
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			})
			// 获取上传图片成功地址
			let location = ref('')
			const succ = (res) => {
				location.value = res.data
				console.log(res)
			}
			// 自定义上传
			const httpreqst = (param) => {
				console.log(param)
				console.log(param.file.name)
				data.value = {
					imageFile: param.file.namefileList
				}
				bmPictures(data).then((res) => {
					console.log(res)
					resolve(res);
				})
			}
			const styA = ref(0)

			const change = (res, fileList) => {
				// console.log(res)
				styA.value = fileList.length
				form.breviary = fileList
				// console.log(fileList)
			}
			const beforeImageUpload = (File) => {
				console.log(File)
				let isLt2M = File.size / 1024 / 1024 < 1
				return new Promise((resolve) => {
					if (isLt2M) {
						resolve(File)
					}
					imageConversion.compressAccurately(File, 930).then((res) => {
						resolve(res);
						// data.value = File.name
					});
				})
			}
			// 图片放大
			const dialogVisible = ref(false)
			let dialogImageUrl = ref('')
			const onpreview = (File) => {
				dialogVisible.value = true
				dialogImageUrl.value = File.url
				console.log(File)
			}
			// 删除图片
			const beforeremove = (File, fileList) => {
				console.log(File)
				console.log(fileList)
				styA.value = 0
			}
			const Remove = (file) => {
				console.log(file)
			}
			// 所属工会选择
			const yes = () => {
				form.choose = options.value
				visible.value = false;
			};
			// 获取工会code
			let gaincode = ref('')
			// 树形选择
			let options = ref('')
			let dendrogram = (node) => {
				options.value = node.unionName
				form.choose = options.value
				gaincode.value = node.unionCode
			}
			const defaultProps = {
				children: 'unionDtoList',
				label: 'unionName',
			}
			const defaultTime2 = [
				new Date(2000, 1, 1, 0, 0, 0),
			]
			let texture = ref([])
			// 关闭当前页
			const store = useStore();
			const current = ref('')
			const expression = ref([])
			const Indexs = ref(null)
			// 提交 按钮
			const router = useRouter();
			const onSubmit = () => {

				// console.log('标题',form.name)
				// console.log('副标题',form.bywork)
				// console.log('缩略图地址',location.value)
				// console.log('类型',form.sex)
				// console.log('是否发布',form.are)
				// console.log('上传附件',address.value)
				// console.log('编辑器内容',form.copyreader)
				if (valueHtml.value == '<p><br></p>') {
					form.copyreader = ''
				} else {
					form.copyreader = valueHtml.value
				}
				var b = ref(new Date(form.value1))
				var begin = ref('')
				begin.value = b.value.getFullYear() + '-' + (b.value.getMonth() + 1) + '-' + b.value.getDate() + ' ' +
					b.value.getHours() + ':' + b.value.getMinutes() + ':' + b.value.getSeconds();
				var start = ref(new Date(begin.value).getTime() / 1000)
				console.log('开始时间', start.value)
				ruleFormRef.value.validate((valid) => {
					console.log(valid)
					let data = {
						// 短信模板编号
						templateCode: form.templateCode,
						//短信内容
						messageContent: form.messageContent,
						// 是否存在变量 
						isVariable: form.isVariable,
						//短信变量模板
						messageVariable: form.messageVariable,
						// 备注
						reason: form.reason,
						// 是否发布
						isRelease: form.isRelease,
					}
					if (valid == true) {
						ConfirmLoading.value = true
						templateAdd(qs.parse(data)).then((res) => {
							console.log(res)
							if (res.data.code == 0) {
								ElMessage({
									message: '提交成功',
									type: "success",
									center: true,
								});
								setTimeout(() => {
									current.value = route.path
									expression.value = store.state.tagsList

									expression.value.forEach((item, index) => {
										if (current.value == item.path) {
											Indexs.value = index
										}
									})
									store.state.tagsList.splice(Indexs.value, 1)
									router.push({
										name: 'smsTemplate',
										params: {
											userId: 1
										}
									});
									ConfirmLoading.value = false
								}, 500)
							}
						})
					} else {
						ElMessage({
							message: "提交失败",
							type: "error",
							center: true,
						});
						ConfirmLoading.value = false
					}
					console.log(form)
				})
			}
			// 编辑器实例，必须用 shallowRef
			const editorRef = shallowRef()

			// 内容 HTML
			const valueHtml = ref('')

			// 模拟 ajax 异步获取内容
			onMounted(() => {

			})

			const toolbarConfig = {
				toolbarKeys: [
					'bold', // 加粗
					'italic', // 斜体
					'through', // 删除线
					'underline', // 下划线
					'bulletedList', // 无序列表
					'numberedList', // 有序列表
					'color', // 文字颜色
					'fontSize', // 字体大小
					'lineHeight', // 行高
					'delIndent', // 缩进
					'indent', // 增进
					'justifyCenter', // 居中对齐
					'justifyJustify', // 两端对齐
					'justifyLeft', // 左对齐
					'justifyRight', // 右对齐
					'undo', // 撤销
					'redo', // 重做
					'clearStyle', // 清除格式
				],
				excludeKeys: [
					'insertImage', // 网络图片
					'bgColor', // 背景色
					'blockquote', // 引用
					'codeBlock', // 代码段
					'emotion', // 表情
					'fontFamily', // 字体
					'headerSelect', // 标题 
					'fullScreen', // 全屏
				],
			}
			// 富文本上传图片地址
			// let fils=ref('')
			// placeholder: '请输入内容...',
			const editorConfig = {
				MENU_CONF: {}
			}
			// 视频上传
			editorConfig.MENU_CONF['uploadVideo'] = {
				// 上传视频的配置
				// 接口
				server: '/admin/api/v1/activity/saveEditorImage',
				// 请求头
				headers: {
					timeout: 10000,
					'accessToken': sessionStorage.getItem('token')
				},
				// 限制上传视频大小
				maxFileSize: 104857600, // 100M
				//上传字段 
				fieldName: 'imageFile',
				maxNumberOfFiles: 1,
				// 上传前回调
				onBeforeUpload(file) {
					console.log(file)
					let fileObj = Object.values(file)[0].data
					console.log(fileObj)
					// 判断视频是否大于100M
					let isLt2M = Object.values(file)[0].data.size
					if (isLt2M < 104857600) {
						return true
					} else if (isLt2M > 104857600) {
						ElMessage({
							message: '视频大小不能超过100MB,请重新上传',
							type: "warning",
							center: true,
						});
						return false
					}
				},
				// 上传成功后回调
				onSuccess(file, res) {
					console.log(file)
					console.log(res)
				},
				// 上传失败回调
				onError(file, res) {
					console.log(file)
					console.log(res)
					if (file.size > 104857600) {
						ElMessage({
							message: '视频大小不能超过100MB,请重新上传',
							type: "warning",
							center: true,
						});
					} else {
						ElMessage({
							message: '上传失败，请稍后再试',
							type: "warning",
							center: true,
						});
					}
				},
			}


			// 图片上传
			editorConfig.MENU_CONF['uploadImage'] = {
				// 上传图片的配置 
				// 接口
				server: '/admin/api/v1/activity/saveEditorImage',
				// 请求头
				headers: {
					timeout: 10000,
					'accessToken': sessionStorage.getItem('token')
				},
				maxFileSize: 1 * 1024 * 1024, // 1M
				//上传字段 
				fieldName: 'imageFile',
				// meta: {
				//   imageFile:fils
				// },
				// 最多可上传几个文件，默认为 100
				maxNumberOfFiles: 1,
				// 上传前回调
				onBeforeUpload(file) {
					console.log(file)
					let fileObj = Object.values(file)[0].data
					console.log(fileObj)
					// 压缩图片
					let isLt2M = Object.values(file)[0].data.size
					if (isLt2M < 1048576) {
						return true
					} else if (isLt2M > 1048576) {
						ElMessage({
							message: '图片大小不能超过1MB,请重新上传',
							type: "warning",
							center: true,
						});
						return false
					}
				},
				// 上传成功后回调
				onSuccess(file, res) {
					console.log(file)
					console.log(res)
				},
				// 上传失败回调
				onError() {
					ElMessage({
						message: '图片大小不能超过1MB,请重新上传',
						type: "warning",
						center: true,
					});
				},
			}

			// 组件销毁时，也及时销毁编辑器
			onBeforeUnmount(() => {
				const editor = editorRef.value
				if (editor == null) return
				editor.destroy()
			})

			const handleCreated = (editor) => {
				editorRef.value = editor // 记录 editor 实例，重要！
				// console.log(editor)
			}
			const visible = ref(false);
			// 标题验证
			const ruleFormRef = ref(null)
			const form = reactive({
				templateCode: "",
				messageContent: "",
				isVariable: "",
				messageVariable: "",
				isRelease: "",
				reason: "",
			})
			// 验证表单提示
			const rules = {
				templateCode: [{
					required: true,
					message: "请填写短信模板编号",
					trigger: "blur"
				}, ],
				messageContent: [{
					required: true,
					message: "请填写短信内容",
					trigger: "blur"
				}, ],
				isVariable: [{
					required: true,
					message: "请选择是否存在变量",
					trigger: "change"
				}, ],
				messageVariable: [{
					required: true,
					message: "请填写短信变量模板",
					trigger: "change"
				}, ],
				isRelease: [{
					required: true,
					message: "请选择是否发布",
					trigger: "change"
				}, ],
				reason: [{
					required: true,
					message: "请填写备注",
					trigger: "change"
				}, ],
			}
			return {
				qingkong,
				header,
				succ,
				data,
				// 按钮加载
				ConfirmLoading,
				// 上传图片
				httpreqst,
				dialogVisible,
				dialogImageUrl,
				onpreview,
				beforeremove,
				Remove,
				styA,
				change,
				beforeImageUpload,
				// handlePictureCardPreview,
				// 编辑器
				onSubmit,
				editorRef,
				valueHtml,
				mode: 'default',
				toolbarConfig,
				editorConfig,
				handleCreated,
				// 标题验证
				ruleFormRef,
				form,
				rules,
				visible,
				texture,
				defaultProps,
				dendrogram,
				defaultTime2,
			}
		}
	}
</script>
<style lang='scss' scoped>
	::v-deep .chart-card::-webkit-scrollbar {
		display: none
	}

	::v-deep .chart-card {
		height: calc(100vh - 145px);
		overflow: auto;
	}

	::v-deep .subject {
		margin-left: 3px;
		font-size: var(--el-form-label-font-size);
		font-weight: bold;
		margin-bottom: 5px;
		color: var(--el-text-color-regular);

		.xingxing {
			color: var(--el-color-danger);
		}
	}

	// .w-e-text-container [data-slate-editor] p{
	//   margin-top: 22px;
	//   margin-bottom: 22px;
	// }
	::v-deep .el-button {
		width: 100px;
		height: 40px;
		font-size: 16px;
	}

	::v-deep .el-form-item__content {
		align-items: flex-end;
	}

	::v-deep .referrals {
		color: #cccccc;
		margin-left: 5px;
		font-weight: 500;
	}

	::v-deep .styB .el-upload--picture-card {
		width: 110px;
		height: 110px;
		line-height: 110px;
	}

	::v-deep .styC .el-upload--picture-card {
		display: none;
	}

	::v-deep .el-form-item {
		align-items: center;
	}

	::v-deep .aaaa {
		width: 50%;
	}

	::v-deep .el-card__body {
		padding: 0;
	}

	::v-deep .el-main {
		overflow: auto !important;
	}

	::v-deep .el-card.is-always-shadow {
		width: 95%;
		margin: auto;
		margin-top: 10px;
		padding-left: 20px;
		padding-right: 20px;
		padding-bottom: 20px;
	}

	::v-deep .tabulation {
		margin-top: 10px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 10px;
		overflow: auto !important;
	}
</style>
<style scoped>
	::v-deep .upload-demo {
		display: flex;
		width: 100%;
	}

	::v-deep .upload-demo .el-upload-list {
		display: flex;
		width: 50%;
	}

	::v-deep .el-input {
		height: 44px !important;
	}

	::v-deep .el-form-item__label {
		width: 125px !important;
		font-weight: bold;
	}
	.a1.hover{	  color: #fff;	  background: #e6a23c;	}
	.b1.hover{
	  color: #fff;
	  background: #409eff;
	}
	.c1.hover{
	  color: #fff;
	  background: #67c23a;
	}
</style>